<template>
  <div
    class="microcart min-h100 cl-accent relative"
    :class="[productsInCart.length ? 'bg-cl-secondary' : 'bg-cl-primary']"
    data-testid="microcart"
  >
    <transition name="fade">
      <div v-if="isEditMode" class="overlay" @click="closeEditMode" />
    </transition>
    <div class="row bg-cl-primary px40">
      <div class="col-xs end-xs">
        <button
          type="button"
          class="p0 brdr-none bg-cl-transparent close"
          @click="closeMicrocartExtend"
          data-testid="closeMicrocart"
        >
          <i class="material-icons py20 cl-accent">
            close
          </i>
        </button>
      </div>
    </div>
    <div class="row middle-xs bg-cl-primary top-sm px40 actions">
      <div class="col-xs-12 col-sm">
        <h2
          v-if="productsInCart.length"
          class="cl-accent mt35 mb35"
        >
          {{ $t('Rental overview') }}
        </h2>
      </div>
    </div>
    <template>
      <div class="bg-cl-white font-size-xs-14 cl-gray pb10 rows-container" v-if="productsInCart.length">
        <div class="row" v-if="segments && segments.from">
          <div class="w170 w-xs-130">
            {{ $t('Reservation from') }}:
          </div>
          <div class="bold cl-matterhorn">
            {{ formatDate(segments.from.value) }}
          </div>
        </div>
        <div class="row" v-if="segments && segments.to">
          <div class="w170 w-xs-130">
            {{ $t('Reservation to') }}:
          </div>
          <div class="bold cl-matterhorn ">
            {{ formatDate(segments.to.value) }}
          </div>
        </div>
        <div v-if="source" class="row">
          <div class="w170 w-xs-130">
            {{ $t('Pickup point') }}:
          </div>
          <div class="bold cl-matterhorn">
            {{ source.name }}
          </div>
        </div>
      </div>
      <ul v-if="productsInCart.length" class="bg-cl-primary m0 px40 products">
        <product v-for="product in productsInCart" :key="product.checksum || product.sku" :product="product" />
      </ul>
      <div class="bg-cl-white pr64 pr37-xs pb20 justify-content-flex-end clearcart-col">
        <clear-cart-button
          class="clear-cart-button--xs"
          v-if="productsInCart.length "
          :icon-xs="true"
          @click.native="clearCart"
        />
      </div>
      <div v-if="productsInCart.length && segments.subtotal && segments.subtotal.value" class="summary px40 cl-accent serif">
        <div class="row pt40">
          <div class="col-xs-6">
            <h3 class="m0  mb20 weight-400 summary-heading">
              {{ $t('Total price') }}
            </h3>
          </div>
          <div class="col-xs-6 align-right h2 total-price-value">
            {{ segments.subtotal.value | price(storeView) }}
          </div>
        </div>
        <div class="row pt0 pb20 weight-700 middle-xs">
          <div class="col-xs h5 note">
            {{ $t('All prices are including VAT.') }}
          </div>
        </div>
      </div>

      <div
        class="pt30 px30 pb60-xs"
        v-if="productsInCart.length && !isCheckoutMode"
      >
        <div class="row middle-xs actions  reverse-column-xs">
          <div class="col-md-6 col-xs-12 col-sm-6">
            <back-button :text="$t('Back to rental').toString()" @click.native="closeMicrocartExtend"
                         class="float-left"
            />
          </div>
          <div class="col-xs-12 col-sm-6 end-sm">
            <router-link to="/checkout">
              <button-full
                class="button-full--mw-14 button-full--no-full py10 float-right"
                @click.native="closeMicrocartExtend"
              >
                {{ $t('Go to checkout') }}
              </button-full>
            </router-link>
            <!-- <instant-checkout v-if="isInstantCheckoutRegistered" class="no-outline button-full block brdr-none w-100 px10 py20 bg-cl-mine-shaft :bg-cl-th-secondary ripple weight-400 h4 cl-white sans-serif fs-medium mt20" /> -->
          </div>
        </div>
      </div>
    </template>
    <div v-if="!productsInCart.length" class="h-100vh overflow-hidden">
      <div class="row middle-xs bg-cl-primary top-sm px40 actions">
        <div class="col-xs-12">
          <h2
            class="cl-accent mt35 mb35"
          >
            {{ $t('Rental overview') }}
          </h2>
        </div>
        <div class="col-xs-12">
          <span>{{ $t('Your shopping cart is empty.') }}</span>
        </div>
      </div>
      <div class="pt60 h-100 bg-cl-silver mt250">
        <div class="row middle-xs actions align-items-flex-end px0 flex">
          <div class="col-md-6 col-xs-12 col-sm-6 align-end ml30">
            <back-button :text="$t('Back to rental').toString()"
                         @click.native="closeMicrocartExtend"
                         class="back-button--empty-cart float-left"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import i18n from '@vue-storefront/i18n';
import { isModuleRegistered } from '@vue-storefront/core/lib/modules';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import VueOfflineMixin from 'vue-offline/mixin';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
// import InstantCheckout from 'src/modules/instant-checkout/components/InstantCheckout.vue'
import { registerModule } from '@vue-storefront/core/lib/modules';

import ClearCartButton from 'theme/components/core/blocks/Microcart/ClearCartButton';
import ButtonFull from 'theme/components/theme/ButtonFull';
import Product from 'theme/components/core/blocks/Microcart/Product.vue';
import EditMode from './EditMode';
import { InstantCheckoutModule } from 'src/modules/instant-checkout';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import globalConfig from 'config';
import BackButton from '../BackButton';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = globalConfig.timezone;

export default {
  components: {
    BackButton,
    Product,
    ClearCartButton,
    ButtonFull
  },
  mixins: [
    VueOfflineMixin,
    EditMode,
    onEscapePress
  ],
  data () {
    return {
      addCouponPressed: false,
      couponCode: '',
      componentLoaded: false,
      isInstantCheckoutRegistered: isModuleRegistered('InstantCheckoutModule')
    };
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  beforeCreate () {
    registerModule(InstantCheckoutModule);
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true;
    });
  },
  computed: {
    segments () {
      return this.totals.reduce((acc, section) => {
        acc[section.code] = section;
        return acc;
      }, {});
    },
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      appliedCoupon: 'cart/getCoupon',
      source: 'cart/getSource',
      totals: 'cart/getTotals',
      isOpen: 'cart/getIsMicroCartOpen',
      isUserLoggedIn: 'user/isLoggedIn'
    }),
    storeView () {
      return currentStoreView();
    }
  },
  methods: {
    ...mapActions({
      applyCoupon: 'cart/applyCoupon'
    }),
    clearCoupon () {
      this.$store.dispatch('cart/removeCoupon');
      this.addCouponPressed = false;
    },
    toggleMicrocart () {
      this.$store.dispatch('ui/toggleMicrocart');
    },
    async setCoupon () {
      const couponApplied = await this.applyCoupon(this.couponCode);
      this.addCouponPressed = false;
      this.couponCode = '';
      if (!couponApplied) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('You\'ve entered an incorrect coupon code. Please try again.'),
          action1: { label: i18n.t('OK') }
        });
      }
    },
    closeMicrocartExtend () {
      this.toggleMicrocart();
      this.$store.commit('ui/setSidebar', false);
      this.addCouponPressed = false;
    },
    onEscapePress () {
      this.toggleMicrocart();
    },
    clearCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('Are you sure you would like to remove all the items from the shopping cart?'),
        action1: { label: i18n.t('Cancel'), action: 'close' },
        action2: {
          label: i18n.t('OK'),
          action: async () => {
            await this.$store.dispatch('cart/deleteBatch', { productsToDelete: this.productsInCart });
            await this.$store.dispatch('cart/clear') // just clear the items without sync
            await this.$store.dispatch('cart/sync', { forceClientState: true })
            await this.$store.dispatch('additionalServices/setBiometricData', null);
            await this.$store.dispatch('checkout/setUserNote', '');
          }
        },
        hasNoTimeout: true
      });
    },
    transferToLogin () {
      this.closeMicrocartExtend();
      this.$store.commit('ui/setAuthElem', 'login');
      EventBus.$emit('modal-show', 'modal-signup');
    },
    translateParts (segment) {
      const splitted = segment.title.split(' (', 3);
      if (!splitted[1]) {
        console.error('description in totals (segments) is wrong, missing () | microcart');
        splitted[1] = '';
      }
      const translatedPart = i18n.t(splitted[0]);
      const notTranslatedPart = splitted[1].split(')', 3);
      return `${translatedPart} ${notTranslatedPart[0]}`;
    },
    formatDate (date) {
      const timezoneReformat = dayjs.tz(date, TIMEZONE).locale('cs').format('LL');
      return timezoneReformat;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-matterhorn: color(matterhorn);
$color-gray: color(gray);
$color-light-gray: color(light-gray);
$color-silver: color(white-smoke);

.cl-matterhorn {
  color: $color-matterhorn;
}
.bg-cl-silver{
  background-color: $color-silver;
}

.cl-gray {
  color: $color-gray;
}

.cl-light-gray {
  color: $color-light-gray;
}

.pt60 {
  padding-top: 60px
}

.close {
  i {
    opacity: 0.6;
  }

  &:hover,
  &:focus {
    i {
      opacity: 1;
    }
  }
}

.clearcart {
  &-col {
    display: flex;
    align-self: center;
  }
}

.mt250 {
  margin-top: 250px;
}

.overflow-hidden {
  overflow: hidden;
}

.products {
  @media (max-width: 767px) {
    padding: 30px 15px;
  }
}

.actions {
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .link {
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      padding: 20px 70px;
      &.checkout {
        margin-top: 55px;
        padding: 0;
      }
    }
  }
}

.summary {
  @media (max-width: 767px) {
    padding: 0 15px;
    font-size: 12px;
  }
}

.summary-heading {
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.total-price-label {
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.total-price-value {
  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.delete-button {
  vertical-align: middle;
}

.coupon-wrapper {
  display: flex;

  .button-outline {
    text-transform: inherit;
    width: 50%;
  }

  .coupon-input {
    margin-right: 20px;
    width: 100%;
  }
}

.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.login-notification {
  color: #fc3711;
  border: 1px solid red;
}

.from {
  padding-bottom: 2px;
}

.to {
  padding-top: 2px;
}

.max-250 {
  max-width: 225px;
}

.opn, .opv {
  font-size: 15px;
}

.note {
  font-size: 14px;
  font-family: 'TTCommons-Light';
}

.align-end {
  align-self: flex-end;
}

.bold {
  font-weight: bold;
}

.button-full--no-full {
  width: auto;
  min-width: auto;
}

.button-full--mw-145::v-deep .wrap {
  min-width: 145px;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-flex-end {
  align-items: flex-end;
}

.float-right {
  float: right;
}
.grid {
  display: grid;
}

.align-center {
  align-items: center;
}

.float-left {
  float: left;
}
.justify-self-center{
  justify-self: center;
}
.min-h100 {
  min-height: 100vh; height: auto; _height: 100vh;
}
.w170 {
  width: 170px;
}
.login-warning {
  display: grid;
  grid-template-columns: 1fr 8fr;
}
.h-100vh {
  height: 100vh;
}

.back-button {
  min-width: 190px;
}
.m0 {
  margin: 0;
}
.back-button--empty-cart {
  min-width: 167px;
  height: 42px;
}
.back-button::v-deep .text  {
  padding-left: 8px !important;
}

.mt0 {
  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.rows-container {
  padding-left: 40px;
}

@media (max-width: 575px) {
  .reverse-column-xs {
    flex-direction: column-reverse;
  }
  .pb60-xs{
    padding-bottom: 60px;
  }

  .rows-container {
    padding-left: 1rem;
  }
}
@media (max-width: 760px) {
  .font-size-xs-14 {
    font-size: 14px;
  }
 .clear-cart-button--xs{
   font-size: 14px;
 }

  .pr37-xs {
  padding-right: 37px;
  }
  .w-xs-130 {
    width: 130px;
  }
}

.row {
  margin: 0 0.1rem;
}
</style>
